import styled, { css } from 'styled-components';
import React from 'react';

import { Link as RouterLink } from 'react-router-dom';

// approach of passing in Object to styled components is similar to https://www.styled-components.com/docs/advanced#tagged-template-literals
// ref: https://github.com/styled-components/styled-components/pull/1775
const H1 = styled.h1([({ theme, styleOf }) => (styleOf ? theme.typography[[styleOf]] : theme.typography[['h1']])]);

const H2 = styled.h2([({ theme, styleOf }) => (styleOf ? theme.typography[[styleOf]] : theme.typography[['h2']])]);

const H3 = styled.h3([({ theme, styleOf }) => (styleOf ? theme.typography[[styleOf]] : theme.typography[['h3']])]);

const H4 = styled.h4([({ theme, styleOf }) => (styleOf ? theme.typography[[styleOf]] : theme.typography[['h4']])]);

const P = styled.p([({ theme, styleOf }) => (styleOf ? theme.typography[[styleOf]] : theme.typography[['p']])]);

const SmallText = styled.p([
    ({ theme, styleOf }) => (styleOf ? theme.typography[[styleOf]] : theme.typography[['smallText']]),
]);

const ReallySmallText = styled.p([
    ({ theme, styleOf }) => (styleOf ? theme.typography[[styleOf]] : theme.typography[['reallySmallText']]),
]);

const Span = styled.span([
    ({ theme, styleOf }) => (styleOf ? theme.typography[[styleOf]] : theme.typography[['span']]),
]);

const Label = styled.label([
    ({ theme, styleOf }) => (styleOf ? theme.typography[[styleOf]] : theme.typography[['label']]),
]);

const Blockquote = styled.blockquote([
    ({ theme, styleOf }) => (styleOf ? theme.typography[[styleOf]] : theme.typography[['blockquote']]),
]);

const A = styled.a([({ theme, styleOf }) => (styleOf ? theme.typography[[styleOf]] : theme.typography[['a']])]);

const Link = styled(({ styleOf, ...props }) => <RouterLink {...props} />)([
    ({ theme, styleOf }) => (styleOf ? theme.typography[[styleOf]] : theme.typography[['a']]),
]);

const SectionHeadingStyled = styled(H2)`
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.spacing[3]};
    text-align: center;
    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        font-size: ${({ theme }) => theme.fontSizes.xlarge};
        margin-bottom: ${({ theme }) => theme.spacing[6]};
    }
`;

// Typography style blocks
const h1LargeStyles = css([({ theme }) => theme.typography.h1large]);

const h1Styles = css([({ theme }) => theme.typography.h1]);

const h1SmallStyles = css([({ theme }) => theme.typography.h1small]);

const h2Styles = css([({ theme }) => theme.typography.h2]);

const h2VariantStyles = css([({ theme }) => theme.typography.h2Variant]);

const h3Styles = css([({ theme }) => theme.typography.h3]);

const h3VariantStyles = css([({ theme }) => theme.typography.h3Variant]);

const h4Styles = css([({ theme }) => theme.typography.h4]);

const pStyles = css([({ theme }) => theme.typography.p]);

const spanStyles = css([({ theme }) => theme.typography.span]);

const labelStyles = css([({ theme }) => theme.typography.label]);

const blockquoteStyles = css([({ theme }) => theme.typography.blockquote]);

const focalSmallStyles = css([({ theme }) => theme.typography.focalSmall]);

const smallTextStyles = css([({ theme }) => theme.typography.smallText]);

const reallySmallTextStyles = css([({ theme }) => theme.typography.reallySmallText]);

const aStyles = css([({ theme }) => theme.typography.a]);

const SecondaryText = styled.span`
    ${smallTextStyles};
    color: ${({ theme }) => theme.palette.grey[500]};
    margin: 0;

    @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
        font-size: ${({ theme }) => theme.fontSizes.medium};
    }
`;

const headingStyles = {
    h2: css`
        ${h2Styles};
        @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
            ${h1SmallStyles};
        }
    `,
    h3: css`
        ${h3Styles};
        @media screen and (min-width: ${({ theme }) => theme.screenSize.mediumTablet}px) {
            ${h2Styles};
        }
    `,
};

const Heading2 = styled.h2`
    ${({ styleOf = 'h2' }) => headingStyles[styleOf]};
    text-align: ${({ align }) => align};
`;

const Heading3 = styled.h3`
    ${({ styleOf = 'h3' }) => headingStyles[styleOf]};
    text-align: ${({ align }) => align};
`;

export {
    h1LargeStyles,
    h1Styles,
    h1SmallStyles,
    h2Styles,
    h2VariantStyles,
    h3Styles,
    h3VariantStyles,
    h4Styles,
    pStyles,
    spanStyles,
    labelStyles,
    blockquoteStyles,
    focalSmallStyles,
    smallTextStyles,
    reallySmallTextStyles,
    aStyles,
    Heading2,
    Heading3,
    H1,
    H2,
    H3,
    H4,
    P,
    SmallText,
    ReallySmallText,
    SecondaryText,
    Span,
    Label,
    Blockquote,
    A,
    Link,
    SectionHeadingStyled,
};
