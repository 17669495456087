
import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';

import { EditorState, convertToRaw, convertFromRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';

import useWindowSize from '../../../../utils/useWindowSize';
import theme from '../../../../themes/default';
import { ToastProvider } from 'react-toast-notifications';
import { useFirestore } from 'react-redux-firebase';

import Link from 'components/Link';

import {
    TBody, TBodyTR, TBodyTD, TBodyTDActions,
    CellWrapper, ActionsCellWrapper, LayoutRow, EnabledLabel,
    DetailContentTR, DetailContentTD,
    DetailsContentWrapper, DetailsContentCard,
    EditCard, Form, FieldSet,
    SwitchLabel, Sections,
    IconContainer,
    EditIcon, DeleteIcon, MoveUpIcon, MoveDownIcon,
} from './Block.style';

import Section from '../Section';

import Switch from '../../../Projects/components/Switch';

import { CancelButton, SaveButton } from '../../../Projects/components/Common/Button.style';
import { InputLabel, InputField } from '../../../Projects/components/Common/Input.style';
import { ActionsList } from '../../../Projects/components/Common/Lists.style';
import { ContentRow } from '../../../Projects/components/Common/Row.style';

import ReactDynamicImport from 'react-dynamic-import';
const loader = () => import('../../../Projects/components/Wysiwyg');
const Editor = ReactDynamicImport({ loader });


const Block = ({ block, blocks, blockIdx, projectId, blocksCount, addNewCback, onDeleteCback }) => {
    const dispatch = useDispatch();
    const [showDetails, toggleDetails] = useState(false);

    const [enabled, setEnabled] = useState(block.enabled || false);
    const [isTwoColumns, setTwoColumns] = useState(block.twoColumns || false);
    const [name, setName] = useState(block.name || '');
    const [sections, setSections] = useState(block.sections || [{}, {}]);

    const db = useFirestore();

    const handleChange = (event, type) => {
        switch(type) {
            case 'name':
                setName(event.target.value);
                break;
            default:
                break;
        }
    };

    const onDelete = (event) => {
        event.preventDefault();
        const confirmed = confirm('Are you sure to do this?');
        if (confirmed) {
            handleDelete();
        }
    };

    const handleDelete = () => {
        if (block.isNew) {
            onDeleteCback(block);
        }
        else {
            db.collection('projects').doc(projectId).collection('blocks').doc(block.id).delete().then(function() {
                console.log("Document successfully deleted!");
            }).catch(function(error) {
                console.error("Error removing document: ", error);
            });
        }
    };

    const handleSectionDataChange = (section, data) => {
        const newSections = [
            ...sections,
        ];
        newSections[section] = {
            ...data,
        };
        setSections(newSections);
    };

    const handleMoveUp = (event) => {
        event.stopPropagation();
        swapOrder(block, blocks[blockIdx - 1]);
    };

    const handleMoveDown = (event) => {
        event.stopPropagation();
        swapOrder(block, blocks[blockIdx + 1]);
    };

    const swapOrder = (blockA, blockB) => {
        const batch = db.batch();

        const blockARef = db.collection('projects').doc(projectId).collection('blocks').doc(blockA.id);
        batch.set(blockARef, {
            ...blockA,
            order: blockB.order,
        });

        const blockBRef = db.collection('projects').doc(projectId).collection('blocks').doc(blockB.id);
        batch.set(blockBRef, {
            ...blockB,
            order: blockA.order,
        });

        batch.commit().then(() => {}).catch(function(error) {
            console.error('Error updating: ', error);
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            enabled,
            name,
            twoColumns: isTwoColumns,
            sections,
            order: block.order !== null ? block.order : blocksCount,
        };

        const collection = db.collection('projects').doc(projectId).collection('blocks');
        if (block.isNew) {
            addNewCback(block);

            collection.add(data)
            .then(function(docRef) {
                console.log('Document successfully created');
            })
            .catch(function(error) {
                console.error('Error creating document: ', error);
            });
        }
        else {
            collection.doc(block.id).update(data)
            .then(function(docRef) {
                console.log('Document successfully updated');
                toggleDetails(false);
            })
            .catch(function(error) {
                console.error('Error updating document: ', error);
            });
        }
    }
    // onClick={() => handleDelete(project)}

    const windowSize = useWindowSize();
    const isMobile = windowSize.width < theme.screenSize.mediumTablet;

    return (
        <TBody key={block.id}>
            <TBodyTR onClick={() => toggleDetails(!showDetails)}>
                <TBodyTD>
                    <CellWrapper>
                        <LayoutRow>{block.name}</LayoutRow>
                    </CellWrapper>
                </TBodyTD>
                {!isMobile && (
                    <TBodyTD>
                        <CellWrapper>
                            <EnabledLabel enabled={enabled}>{enabled ? 'Enabled' : 'Disabled'}</EnabledLabel>
                        </CellWrapper>
                    </TBodyTD>
                )}
                <TBodyTDActions isVisible={showDetails}>
                    <ActionsCellWrapper>
                        {!block.isNew && (
                            <Fragment>
                                {blockIdx > 0 && <IconContainer onClick={(event) => handleMoveUp(event)}>
                                    <MoveUpIcon />
                                </IconContainer>}
                                {blockIdx < blocksCount - 1 && <IconContainer onClick={(event) => handleMoveDown(event)}>
                                    <MoveDownIcon />
                                </IconContainer>}
                            </Fragment>
                        )}
                        <IconContainer>
                            <DeleteIcon onClick={onDelete} />
                        </IconContainer>
                    </ActionsCellWrapper>
                </TBodyTDActions>
            </TBodyTR>
            {showDetails && (
                <DetailContentTR>
                    <DetailContentTD colSpan={isMobile ? 2 : 3}>
                        <DetailsContentWrapper>
                            <EditCard>
                                <Form onSubmit={handleSubmit}>
                                    <FieldSet>
                                        <ContentRow first right center>
                                            <Switch enabled={enabled} onToggle={setEnabled} />
                                            <SwitchLabel>Enable</SwitchLabel>
                                        </ContentRow>
                                        <ContentRow>
                                            <InputLabel>Name</InputLabel>
                                            <InputField
                                                type="text"
                                                name="name"
                                                autocomplete="off"
                                                spellcheck="false"
                                                value={name}
                                                onChange={(event) => handleChange(event, 'name')}
                                            />
                                        </ContentRow>
                                        <ContentRow right center>
                                            <Switch enabled={isTwoColumns} onToggle={setTwoColumns} />
                                            <SwitchLabel>Toggle Two Columns</SwitchLabel>
                                        </ContentRow>
                                        <ToastProvider
                                            autoDismiss
                                            autoDismissTimeout={2000}
                                            placement="bottom-center"
                                        >
                                            <Sections isTwoColumns={isTwoColumns}>
                                                <Section
                                                    data={sections[0]}
                                                    block={block}
                                                    isTwoColumns={isTwoColumns}
                                                    onDataChange={(data) => handleSectionDataChange(0, data)}
                                                />
                                                {isTwoColumns && (
                                                    <Section
                                                        data={sections[1]}
                                                        block={block}
                                                        isTwoColumns={isTwoColumns}
                                                        onDataChange={(data) => handleSectionDataChange(1, data)}
                                                    />
                                                )}
                                            </Sections>
                                        </ToastProvider>
                                        <ActionsList>
                                            <CancelButton type="button" onClick={() => toggleDetails(false)}>Cancel</CancelButton>
                                            <SaveButton type="submit">Save</SaveButton>
                                        </ActionsList>
                                    </FieldSet>
                                </Form>
                            </EditCard>
                        </DetailsContentWrapper>
                        <DetailsContentCard></DetailsContentCard>
                    </DetailContentTD>
                </DetailContentTR>
            )}
        </TBody>
    );
}

export default Block;
