
import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';

import { useSpring } from 'react-spring'

import { EditorState, convertToRaw, convertFromRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';

// import Snackbar from '@material-ui/core/Snackbar';
// import { Alert } from '@material-ui/lab';

import useWindowSize from '../../../../utils/useWindowSize';
import theme from '../../../../themes/default';

import { useFirestore } from 'react-redux-firebase';

import Link from 'components/Link';

import { UPLOAD_STATE, uploadFile } from '../../../../utils/uploader';
import { getImagePreview, getFileConfigWithSizes } from '../../../../utils/images';

import getUploader from '../../../../utils/s3uploader';

import {
    TBody, TBodyTR, TBodyTD, TBodyTDActions,
    CellWrapper, ActionsCellWrapper, LayoutRow, EnabledLabel,
    DetailContentTR, DetailContentTD,
    DetailsContentWrapper, DetailsContentCard,
    EditCard, Form, FieldSet,
    SwitchLabel,
    IconContainer,
    EditIcon, DeleteIcon, MoveUpIcon, MoveDownIcon,
} from './Project.style';

import Facts from '../Facts';

import {
    ThumbsContainer, Thumb, ThumbInner, Img,
} from '../../../Project/components/Section/Section.style';

import Switch from '../Switch';

import { useDropzone } from 'react-dropzone';

import { CancelButton, SaveButton } from '../Common/Button.style';
import { InputLabel, InputField } from '../Common/Input.style';
import { ActionsList } from '../Common/Lists.style';
import { ContentRow } from '../Common/Row.style';


import ReactDynamicImport from "react-dynamic-import";
const loader = () => import(`../Wysiwyg`);
const Editor = ReactDynamicImport({ loader });

const Project = ({ uploader, projects, project, projectIdx, projectsCount, addNewCback, onDeleteCback }) => {
    const dispatch = useDispatch();
    const [showDetails, toggleDetails] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    // const [image, setImage] = useState(project.image || null);
    const [enabled, setEnabled] = useState(project.enabled || false);
    const [name, setName] = useState(project.name || '');
    const [info, setInfo] = useState(project.info || '');
    const [year, setYear] = useState(project.year || '');
    const [facts, setFacts] = useState(project.facts || []);
    // const [editorState, setEditorState] = useState(EditorState.createEmpty());

    // const content = '<p>sdf&nbsp;</p><p><span style="color: rgb(26,188,156);"><strong>asdfa</strong></span>&nbsp;</p><p style="text-align:center;"><em>sdfa</em></p>';
    // const contentState = ContentState.createFromBlockArray(convertFromHTML(content))

    const [imageOpacityState, setImageOpacityState] = useState(image ? 1 : 0);
    const { imageOpacity } = useSpring({
        // config: { mass: 1, tension: 170, friction: 70 },
        from: { imageOpacity: 0 },
        imageOpacity: imageOpacityState,
    });

    let contentState = EditorState.createEmpty();
    if (project.description) {
        const rawContent = convertFromRaw(JSON.parse(project.description.raw));
        contentState = EditorState.createWithContent(rawContent);
    }
    const [editorState, setEditorState] = useState(contentState);
    const [openSuccess, setOpenSuccess] = useState(false);

    const availableSizes = [{
        name: 'full',
        size: 2400,
        quality: 0.87,
    }, {
        name: 'mobile',
        size: 1200,
        quality: 0.95,
    }, {
        name: 'cover',
        size: 640,
        quality: 0.87,
    }, {
        name: 'textBg',
        size: 10,
        quality: 1.0,
        filters: [{
            type: 'blur',
            amount: '0.5px',
            fill: 'white',
        }],
    }, {
        name: 'blur',
        size: 300,
        quality: 1.0,
        filters: [{
            type: 'blur',
            amount: '7px',
            fill: 'white',
        }],
    }];

    const getUploaderProps = project.isNew ? () => ({}) : getUploader({
        collection: `${project.id.substr(0, 6)}`,
        sizes: availableSizes,
    });
    const { key, getRootProps, getInputProps } = getUploaderProps();

    const tmpImageIdx = uploader?.[key]?.ordered?.[0];
    const tmpImage = uploader?.[key]?.items?.[tmpImageIdx];
    const image = tmpImage || project?.image;

    const db = useFirestore();

    const handleNewFact = () => {
        const newFacts = [...facts];
        newFacts.push({
            name: '',
            value: '',
        });
        setFacts(newFacts);
    };

    const handleDeleteFact = (index) => {
        const newFacts = [...facts];
        newFacts.splice(index, 1);
        setFacts(newFacts);
    };

    const handleChangeFact = (event, index, field) => {
        const newFacts = [...facts];
        newFacts[index][field] = event.target.value;
        setFacts(newFacts);
    };


    const handleChange = (event, type) => {
        switch(type) {
            case 'name':
                setName(event.target.value);
                break;
            case 'info':
                setInfo(event.target.value);
                break;
            case 'year':
                setYear(event.target.value);
                break;
            // case 'image':
            //     setImage(event);
            //     break;
            default:
                break;
        }
    };

    const onDelete = (event) => {
        event.preventDefault();
        const confirmed = confirm('Are you sure to do this?');
        if (confirmed) {
            handleDelete();
        }
    };

    const handleDelete = () => {
        if (project.isNew) {
            onDeleteCback(project);
        }
        else {
            db.collection('projects').doc(project.id).delete().then(function() {
                console.log("Document successfully deleted!");
            }).catch(function(error) {
                console.error("Error removing document: ", error);
            });
        }
    };

    const handleMoveUp = (event) => {
        event.stopPropagation();
        swapOrder(project, projects[projectIdx - 1]);
    };

    const handleMoveDown = (event) => {
        event.stopPropagation();
        swapOrder(project, projects[projectIdx + 1]);
    };

    const swapOrder = (projectA, projectB) => {
        const batch = db.batch();

        const projectARef = db.collection('projects').doc(projectA.id);
        batch.set(projectARef, {
            ...projectA,
            order: projectB.order,
        });

        const projectBRef = db.collection('projects').doc(projectB.id);
        batch.set(projectBRef, {
            ...projectB,
            order: projectA.order,
        });

        batch.commit().then(() => {}).catch(function(error) {
            console.error('Error updating: ', error);
        });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        // setOpenSuccess(true);
        // return;

        const descriptionRaw = convertToRaw(editorState.getCurrentContent());

        const data = {
            enabled,
            name,
            info,
            year,
            image: getFileConfigWithSizes(image, availableSizes),
            facts,
            description: {
                raw: JSON.stringify(descriptionRaw),
                html: draftToHtml(descriptionRaw),
            },
            order: project.order !== null ? project.order : projectsCount,
        };
        console.log('data', data)

        const collection = db.collection('projects');
        if (project.isNew) {
            addNewCback(project);

            collection.add(data)
            .then(function(docRef) {
                console.log('Document successfully created');
            })
            .catch(function(error) {
                console.error('Error creating document: ', error);
            });
        }
        else {
            collection.doc(project.id).update(data)
            .then(function(docRef) {
                console.log('Document successfully updated');
                toggleDetails(false);
            })
            .catch(function(error) {
                console.error('Error updating document: ', error);
            });
        }
    }

    const thumb = image && (
        <Thumb key={image.name || image.path} style={{
            opacity: image.percent ? (image.percent / 100.0) : 1,
        }}>
            <ThumbInner>
                <Img src={getImagePreview(image?.cover || image)} />
            </ThumbInner>
        </Thumb>
    );

    // useEffect(() => () => {
    //     // Make sure to revoke the data uris to avoid memory leaks
    //     files.forEach(file => URL.revokeObjectURL(file.preview));
    // }, [files]);

    let background = '#fff';
    if (!enabled) {
        background = '#ffcccb';
    }
    if (project.isNew) {
        background = '#ffffe0';
    }

    const windowSize = useWindowSize();
    const isMobile = windowSize.width < theme.screenSize.mediumTablet;

    return (
        <TBody>
            {/* <TBodyTR> */}
            <TBodyTR onClick={() => toggleDetails(!showDetails)} background={background}>
                <TBodyTD>
                    <CellWrapper>
                        <LayoutRow>{project.name}</LayoutRow>
                    </CellWrapper>
                </TBodyTD>
                {!isMobile && (
                    <TBodyTD>
                        <CellWrapper>
                            <EnabledLabel enabled={enabled}>{enabled ? 'Enabled' : 'Disabled'}</EnabledLabel>
                        </CellWrapper>
                    </TBodyTD>
                )}
                <TBodyTDActions isVisible={showDetails}>
                    <ActionsCellWrapper>
                        {!project.isNew && (
                            <Fragment>
                                {projectIdx > 0 && <IconContainer onClick={(event) => handleMoveUp(event)}>
                                    <MoveUpIcon />
                                </IconContainer>}
                                {projectIdx < projectsCount - 1 && <IconContainer onClick={(event) => handleMoveDown(event)}>
                                    <MoveDownIcon />
                                </IconContainer>}
                                <IconContainer>
                                    <Link to={`/project/${project.id}/`}>
                                        <EditIcon />
                                    </Link>
                                </IconContainer>
                            </Fragment>
                        )}
                        <IconContainer>
                            <DeleteIcon onClick={onDelete} />
                        </IconContainer>
                    </ActionsCellWrapper>
                </TBodyTDActions>
            </TBodyTR>
            {showDetails && (
                <DetailContentTR>
                    <DetailContentTD colSpan={isMobile ? 2 : 3}>
                        <DetailsContentWrapper>
                            {/* <Snackbar open={openSuccess} autoHideDuration={6000} onClose={setOpenSuccess}>
                                <Alert elevation={6} variant="filled" onClose={setOpenSuccess} severity="success">
                                    This is a success message!
                                </Alert>
                            </Snackbar> */}
                            <EditCard>
                                <Form onSubmit={handleSubmit}>
                                    <FieldSet>
                                        <ContentRow first right center>
                                            <Switch enabled={enabled} onToggle={setEnabled} />
                                            <SwitchLabel>Enable</SwitchLabel>
                                        </ContentRow>
                                        <ContentRow>
                                            <InputLabel>Name</InputLabel>
                                            <InputField
                                                type="text"
                                                name="name"
                                                autocomplete="off"
                                                spellcheck="false"
                                                value={name}
                                                onChange={(event) => handleChange(event, 'name')}
                                            />
                                        </ContentRow>
                                        <ContentRow>
                                            <InputLabel>Info</InputLabel>
                                            <InputField
                                                type="text"
                                                name="info"
                                                autocomplete="off"
                                                spellcheck="false"
                                                value={info}
                                                onChange={(event) => handleChange(event, 'info')}
                                            />
                                        </ContentRow>
                                        <ContentRow>
                                            <InputLabel>Year</InputLabel>
                                            <InputField
                                                type="text"
                                                name="year"
                                                autocomplete="off"
                                                spellcheck="false"
                                                value={year}
                                                onChange={(event) => handleChange(event, 'year')}
                                            />
                                        </ContentRow>
                                        <ContentRow>
                                            <InputLabel>Description</InputLabel>
                                            <Editor
                                                editorState={editorState}
                                                // wrapperClassName="demo-wrapper"
                                                // editorClassName="demo-editor"
                                                onStateChange={(state) => setEditorState(state)}
                                            />
                                        </ContentRow>
                                        {!project.isNew && (
                                            <ContentRow>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    <p>Drag 'n' drop the image here, or click to select one</p>
                                                </div>
                                                <ThumbsContainer>
                                                    {thumb}
                                                </ThumbsContainer>
                                            </ContentRow>
                                        )}
                                        <ContentRow>
                                            <InputLabel>Facts</InputLabel>
                                            <Facts
                                                facts={facts}
                                                addNewFact={handleNewFact}
                                                onDeleteFact={handleDeleteFact}
                                                onChangeFact={handleChangeFact}
                                            />
                                        </ContentRow>
                                        <ActionsList>
                                            <CancelButton type="button" onClick={() => toggleDetails(false)}>Cancel</CancelButton>
                                            <SaveButton type="submit">Save</SaveButton>
                                        </ActionsList>
                                    </FieldSet>
                                </Form>
                            </EditCard>
                        </DetailsContentWrapper>
                        <DetailsContentCard></DetailsContentCard>
                    </DetailContentTD>
                </DetailContentTR>
            )}
        </TBody>
    );
}


const mapStateToProps = (state) => {
    return {
        uploader: state.uploader,
    };
};

export default connect(mapStateToProps, null)(Project);
