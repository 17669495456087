import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

import app from './app';
import uploader from './uploader';
import page from 'components/Page/reducers';

const rootReducer = (history) => combineReducers({
    router: connectRouter(history),
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    app,
    page,
    uploader
});

export default rootReducer
