import { put, takeEvery, all, call, take } from 'redux-saga/effects';

import * as pageActions from 'components/Page/actions';
import { ActionTypes } from '../../actions';

function* load(action) {
    yield put(pageActions.loaded.create('Project'));
}

function* preload(action) {
    yield put(pageActions.preloaded.create('Project'));
}


export default function* pageSaga() {
    yield all([
        takeEvery(action => action.type === pageActions.preload.ACTION && action.pageId === 'Project', preload),
        takeEvery(action => action.type === pageActions.load.ACTION && action.pageId === 'Project', load),
    ]);
}
