import axios from 'axios';

const UPLOAD_STATE = {
    Progress: 'progress',
    Done: 'done',
};

const getImageVideoSize = (type, src) => new Promise((resolve, reject) => {
    if (type === 'image') {
        const img = document.createElement('img');
        img.onload = () => {
            resolve({
                width: img.width,
                height: img.height,
            });
        };
        img.src = src;
    }
    else {
        const video = document.createElement('video');
        video.onload = () => {
            resolve({
                width: video.videoWidth,
                height: video.videoHeight,
            });
        };
        video.src = src;
    }
});

const uploadFile = ({
    file,
    index = 0,
    stateChangeCback = (type, file, index, data) => {},
}) => new Promise((resolve, reject) => {
    const signFormData = {
        fileName : file.name,
        fileType : file.type,
    };

    axios.post('/api/s3/sign/', signFormData)
    .then(response => {
        const returnData = response.data.data.returnData;
        const { signedRequest, url, key } = returnData;

        const headers = {
            'content-type': 'image/*',
        };

        const fileType = file.type.startsWith('video/') ? 'video' : 'image';

        if (fileType === 'video') {
            headers['content-type'] = 'video/*';
        }

        // Put the fileType in the headers for the upload
        const options = {
            headers,
            onUploadProgress: (progressEvent) => {
                const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                stateChangeCback(UPLOAD_STATE.Progress, file, index, {percent});
            },
        };

        axios.put(signedRequest, file, options)
        .then(result => {
            getImageVideoSize(fileType, url)
            .then(size => {
                const data = {
                    url,
                    fileType,
                    public_id: key,
                    original_filename: file.name,
                    type: 's3',
                    ...size,
                };

                stateChangeCback(UPLOAD_STATE.Done, file, index, data );
                resolve(data);
            })
            .catch((error) => {
                console.log('ERROR getImageVideoSize', error);
            });
        })
        .catch(error => {
            console.log('ERROR', error);
        });
    })
    .catch(error => {
        console.log('Axios error', JSON.stringify(error));
    });
});

const uploadFiles = ({
    files,
    headers = {
        'content-type': 'image/*',
    },
    stateChangeCback = (type, file, index, data) => {},
}) => new Promise((resolve, reject) => {
    const uploadingFiles = [];
    filteredFiles.map((file, index) => {
        uploadingFiles.append(uploadFile(file, index, stateChangeCback));
    });

    Promise.all(uploadingFiles)
    .then((values) => {
        resolve(values);
    })
    .catch((error) => {
        console.log('All error', error);
    });

    // // Initial FormData
    // const formData = new FormData();
    // formData.append('file', file);
    // // formData.append('tags', `codeinfuse, medium, gist`);
    // formData.append('upload_preset', 'smf4Km77CBRb43QpEFPabQJ59a6BqCaR'); // Replace the preset name with your own
    // formData.append('api_key', '163316914714926'); // Replace API key with your own Cloudinary key
    // formData.append('timestamp', (Date.now() / 1000) | 0);

    // // Make an AJAX upload request using Axios (replace Cloudinary URL below with your own)
    // return axios.post("https://api.cloudinary.com/v1_1/codeinfuse/image/upload", formData, {
    //     headers: { "X-Requested-With": "XMLHttpRequest" },
    // }).then(response => {
    //     const data = response.data;
    //     const fileURL = data.secure_url // You should store this URL for future references in your app
    //     // console.log('uploaded', data);
    //     handleChange(data, 'image');
    // })

    // // Once all the files are uploaded
    // axios.all(uploaders).then(() => {
    //     // ... perform after upload is successful operation
    //     // console.log('uploaders', uploaders)
    // });
});

export {
    UPLOAD_STATE,
    uploadFile,
    uploadFiles,
};
