import React from 'react';
import PropTypes from 'prop-types';
import { renderRoutes, matchRoutes } from 'react-router-config';

import App from './pages/App/';
import Login from './pages/Login/';
import Projects from './pages/Projects/';
import Project from './pages/Project/';
import Pages from './pages/Pages/';


const routes = [
    {
        component: App,
        routes: [
            {
                path: '/projects/',
                exact: true,
                component: Projects,
            },
            {
                path: '/project/:id/',
                exact: true,
                component: Project,
            },
            {
                path: '/pages',
                exact: true,
                component: Pages,
            },
            {
                path: '/',
                exact: true,
                component: Login,
            },
        ],
    },
];

/**
 * Find preload sagas
 * @param location - current location
 * @returns {Array}
 */
const getPreloadSagasWithArgs = (location) => {
    const basename  = '';
    const pathname = location.pathname.slice(basename.length);
    const branch = matchRoutes(routes, pathname);
    const preloadSagasWithArgs = [];

    branch.forEach(({ route, match }) => {
        if (route.component.getPreloadSaga) {
            preloadSagasWithArgs.push({
                saga: route.component.getPreloadSaga(),
                args: {
                    params: match.params,
                    pathname,
                    search: location.search,
                    hash: location.hash,
                },
            });
        }
    });

    return preloadSagasWithArgs;
};

// Render routes
const renderChildRoutes = ({ route }) => renderRoutes(route.routes);

class ContextProvider extends React.Component {
    static childContextTypes = {
        insertCss: PropTypes.func,
    };

    getChildContext() {
        return { ...this.props.context };
    }

    render() {
        return React.Children.only(this.props.children);
    }
}

ContextProvider.propTypes = {
    context: PropTypes.object.isRequired,
};

export { renderChildRoutes, ContextProvider, getPreloadSagasWithArgs };

export default routes;
