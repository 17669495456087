import { all, fork } from 'redux-saga/effects';

import admin from './Projects/sagas';
import project from './Project/sagas';
import info from './Project/sagas';
import pages from './Pages/sagas';

export default function* () {
    yield all([fork(admin), fork(project), fork(pages)]);
}
