
import React, { Fragment, useState, useRef } from 'react';

import { useDrag } from 'react-use-gesture';
import { useSprings, animated } from 'react-spring';

import useWindowSize from '../../../../utils/useWindowSize';
import theme from '../../../../themes/default';

import {
    Table, THead, THeadTH, TBody,
} from './Projects.style';

import Project from '../Project';

// import Icon from 'components/Icon';
// import { IconApartment } from 'components/icons';
// https://material-ui.com/components/icons/

import { Wrapper, Container } from '../Common/Layout.style';


// Returns fitting styles for dragged/idle items
const fn = (order, down, originalIndex, curIndex, y) => index =>
  down && index === originalIndex
    ? { y: curIndex * 100 + y, scale: 1.1, zIndex: '1', shadow: 15, immediate: n => n === 'y' || n === 'zIndex' }
    : { y: order.indexOf(index) * 100, scale: 1, zIndex: '0', shadow: 1, immediate: false }

const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

const swap = (array, moveIndex, toIndex) => {
    /* #move - Moves an array item from one position in an array to another.
        Note: This is a pure function so a new array will be returned, instead
        of altering the array argument.
        Arguments:
        1. array     (String) : Array in which to move an item.         (required)
        2. moveIndex (Object) : The index of the item to move.          (required)
        3. toIndex   (Object) : The index to move item at moveIndex to. (required)
    */
    const item = array[moveIndex];
    const length = array.length;
    const diff = moveIndex - toIndex;
    
    if (diff > 0) {
        // move left
        return [
            ...array.slice(0, toIndex),
            item,
            ...array.slice(toIndex, moveIndex),
            ...array.slice(moveIndex + 1, length)
        ];
    }
    else if (diff < 0) {
        // move right
        const targetIndex = toIndex + 1;
        return [
            ...array.slice(0, moveIndex),
            ...array.slice(moveIndex + 1, targetIndex),
            item,
            ...array.slice(targetIndex, length)
        ];
    }
    return array;
};

const Projects = ({ projects, emptyProjects, projectsCount, addNewCback, onDeleteCback }) => {
    // // Store indicies as a local ref, this represents the item order
    // const order = useRef(items.map((_, index) => index));
    // // Create springs, each corresponds to an item, controlling its transform, scale, etc.
    // const [springs, setSprings] = useSprings(items.length, fn(order.current)); 
    // const bind = useDrag(({ args: [originalIndex], down, movement: [, y] }) => {
    //     console.log('bind', originalIndex, down, y);
    //     const curIndex = order.current.indexOf(originalIndex);
    //     const curRow = clamp(Math.round((curIndex * 100 + y) / 100), 0, items.length - 1);
    //     const newOrder = swap(order.current, curIndex, curRow);
    //     // Feed springs new style data, they'll animate the view without causing a single render
    //     setSprings(fn(newOrder, down, originalIndex, curIndex, y));
    //     if (!down) order.current = newOrder;
    // });

    const windowSize = useWindowSize();
    const isMobile = windowSize.width < theme.screenSize.mediumTablet;

    return (
        <Wrapper>
            <Container>
                <Table>
                    <colgroup>
                        <col width={isMobile ? '50%' : '100%'}></col>
                        {!isMobile && (<col width="50%"></col>)}
                        <col width="85"></col>
                    </colgroup>
                    <THead>
                        <tr>
                            <THeadTH>Project</THeadTH>
                            {!isMobile && (<THeadTH>Status</THeadTH>)}
                            <THeadTH>&nbsp;</THeadTH>
                        </tr>
                    </THead>
                    {/* {items.map(({ zIndex, shadow, y, scale }, i) => { */}
                    {/* {items.map((item, i) => {
                        const project = items[i];
                        return (
                            <TBody
                                // {...bind(i)}
                                // key={i}
                                // style={{
                                //     zIndex,
                                //     boxShadow: shadow.interpolate(s => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`),
                                //     y,
                                //     scale
                                // }}
                            >
                                <Project
                                    key={project.id}
                                    project={project}
                                    projectsCount={projectsCount}
                                    addNewCback={addNewCback}
                                    onDeleteCback={onDeleteCback}
                                />
                            </TBody>
                        );
                    })} */}
                    
                    {projects.map((project, i) => (
                        <Project
                            key={project.id}
                            project={project}
                            projects={projects}
                            projectIdx={i}
                            projectsCount={projectsCount}
                            addNewCback={addNewCback}
                            onDeleteCback={onDeleteCback}
                        />
                    ))}
                    {emptyProjects.map(project => (
                        <Project
                            key={project.id}
                            project={project}
                            projectsCount={projectsCount}
                            addNewCback={addNewCback}
                            onDeleteCback={onDeleteCback}
                        />
                    ))}
                </Table>
            </Container>
        </Wrapper>
    );
}

export default Projects;
