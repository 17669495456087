import { put, takeEvery, all, call, take } from 'redux-saga/effects';

import * as pageActions from 'components/Page/actions';
import { ActionTypes } from '../../actions';

import * as firebase from 'firebase/app';
import { getFirebase } from 'react-redux-firebase';

export function* loadProjects({ params }) {
    const auth = firebase.auth();
    // console.log('auth', auth);

    const db = firebase.firestore();
    // console.log('firestore', firestore);
    
    //   firestore.collection('goalz').doc(goalID).get().then((doc) => {
    //    if(doc.exists){
    //     const data = doc.data()
    //     dispatch({ type: 'GET_GOAL', data }) 
    //    }else{
    //     console.log('does not exist')
    //    }
    
    //   })
    //  }

    // const { id = 1 } = params || {};
    // yield put(loadProblems());
    // yield put(loadProblem(slug));
}

function* load(action) {
    yield loadProjects(action);
    yield put(pageActions.loaded.create('Projects'));
}

function* preload(action) {
    yield loadProjects(action);
    // Block until post is loaded
    // yield take([ActionTypes.PROBLEMS_LOADED, ActionTypes.PROBLEM_LOADED]);
    yield put(pageActions.preloaded.create('Projects'));
}

function* createNewProject(action) {
    const { name } = action;

    const fb = getFirebase();
    const db = fb.firestore();

    var docData = {
        name,
        order: 1,
        objectExample: {
            foo: 'foo',
            bar: {
                a: 1,
                b: true,
            },
        },
    };

    db.collection("projects").add(docData)
    .then(function(docRef) {
        console.log("Document written with ID: ", docRef.id, docRef);
    })
    .catch(function(error) {
        console.error("Error adding document: ", error);
    });
    // let result;
    // try {
    //     db.collection('projects').add(docData)
    //     result = yield call(db.collection('projects').add, docData);
    //     console.log("Document successfully written!", result);
    // }
    // catch (error) {
    //     console.error("Error writing document: ", error);
    // }
}

export default function* pageSaga() {
    yield all([
        takeEvery(action => action.type === pageActions.preload.ACTION && action.pageId === 'Projects', preload),
        takeEvery(action => action.type === pageActions.load.ACTION && action.pageId === 'Projects', load),
        takeEvery(action => action.type === 'new-project', createNewProject),
    ]);
}
