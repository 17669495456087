// https://github.com/armand1m/react-with-firebase-auth/blob/master/src/index.tsx
import React, { Fragment } from 'react';

import { useDispatch } from 'react-redux';
import asPage from '../../components/Page';

import Link from 'components/Link';

import {
    Wrapper, Container,
    Title, Text,
    SignIn, Logout,
    LinkWrapper,
} from './Login.style';

import { useSelector } from 'react-redux'
import { useFirebase, isLoaded, isEmpty } from 'react-redux-firebase';

import { updateUser } from '../../actions';


const Login = () => {
    const dispatch = useDispatch();

    const firebase = useFirebase();

    const auth = useSelector(state => state.firebase.auth)

    const providers = {
        googleProvider: new firebase.auth.GoogleAuthProvider(),
    };

    const signOut = () => {
        firebase.auth().signOut();
    };
    const signInWithGoogle = () => {
        const provider = providers.googleProvider;
        firebase.auth().signInWithPopup(provider).then(function(result) {
            if (result.additionalUserInfo.isNewUser) {
                const user = result.user;
                dispatch(updateUser(user.uid));
                signOut();
            }
            // // This gives you a Google Access Token. You can use it to access the Google API.
            // const token = result.credential.accessToken;
            // // The signed-in user info.
            // const user = result.user;
            // console.log('user', user);
            // console.log(token);
            // console.log('result.additionalUserInfo.isNewUser', result.additionalUserInfo.isNewUser);
            // console.log('result', result);
        }).catch(function(error) {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            const credential = error.credential;
            // ...
            console.log('error', errorCode, errorMessage, email, credential);
        });
    };

    const loggedIn = !isEmpty(auth);
    const loaded = isLoaded(auth);

    return (
        <Wrapper>
            {!loaded && (<Title>{'Loading'}</Title>)}
            {loaded && (
                <Container>
                    <Title>{loggedIn ? `Hello, ${auth.displayName}` : 'Please sign in'}</Title>
                    {!loggedIn && <SignIn onClick={() => signInWithGoogle()}>Sign In</SignIn>}
                    {loggedIn && <Logout onClick={() => signOut()}>Sign Out</Logout>}

                    <LinkWrapper>
                        {loggedIn && <Link to={'/projects'}>Projects</Link>}
                        {loggedIn && <Link to={'/pages'}>Pages</Link>}
                    </LinkWrapper>
                </Container>
            )}
        </Wrapper>
    );
};


export default asPage(Login, 'Login');

