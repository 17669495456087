// @flow
import { getAction, ActionTypes } from './helpers';

export const setLocale = (locale) => ({
    type: getAction('LOCALE', ActionTypes.SET),
    data: locale,
});

export const updateUser = (id) => ({
    type: 'UPDATE_USER',
    id,
});

export const createUpload = (id, data) => ({
    type: 'CREATE_UPLOAD',
    id,
    key,
    data,
});

export const updateUpload = (id, data) => ({
    type: 'UPDATE_UPLOAD',
    id,
    key,
    data,
});

export const removeUpload = (id) => ({
    type: 'REMOVE_UPLOAD',
    id,
    key,
});

export {
    getAction, ActionTypes,
};
