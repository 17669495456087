import React, { Fragment, useState } from 'react';
import { getParams } from 'components/Page/selectors';

import { useSelector } from 'react-redux'
import { useFirebase, isLoaded, isEmpty } from 'react-redux-firebase';

import { connect } from 'react-redux';
import asPage from 'components/Page';

import { firestoreConnect } from 'react-redux-firebase';

import Blocks from './components/Blocks';

import { AddButton } from '../Projects/components/Common/Button.style';
import { ActionsList } from '../Projects/components/Common/Lists.style';
import { ContentRow } from '../Projects/components/Common/Row.style';
import { Wrapper, Container } from '../Projects/components/Common/Layout.style';

const Project = ({ params, projectId, blocks }) => {
    const [emptyBlocks, setNewEmptyBlocks] = useState([]);

    const firebase = useFirebase();
    const firebaseAppAuth = firebase.auth();
    const auth = useSelector(state => state.firebase.auth)

    if (!isLoaded(auth)) {
        return <div>loading...</div>;
    }

    if (isEmpty(auth)) {
        return (
            <Redirect push to={/login/} />
        )
    }

    const hasBlocks = blocks.length > 0 || emptyBlocks.length > 0;

    const blocksOrdered = blocks.sort(function (a, b) { return (a.order > b.order) ? 1 : (a.order < b.order) ? -1 : 0; });
    
    return (
        <div>
            {hasBlocks && (
                <Blocks
                    blocks={blocksOrdered}
                    projectId={projectId}
                    emptyBlocks={emptyBlocks}
                    blocksCount={blocks.length}
                    onDeleteCback={(block) => {
                        const tmpEmptyBlocks = emptyBlocks.filter(b => b.id != block.id);
                        setNewEmptyBlocks(tmpEmptyBlocks);
                    }}
                    addNewCback={(block) => {
                        const tmpEmptyBlocks = emptyBlocks.filter(b => b.id != block.id);
                        setNewEmptyBlocks(tmpEmptyBlocks);
                    }}
                />
            )}

            <Wrapper noMargin>
                <Container noShadow>
                    <ActionsList>
                        <AddButton
                            type="button"
                            onClick={() => setNewEmptyBlocks([
                                ...emptyBlocks,
                                {
                                    id: '_' + Math.random().toString(36).substr(2, 9),
                                    isNew: true,
                                    order: null,
                                },
                            ])}
                        >
                            Add New Block
                        </AddButton>
                    </ActionsList>
                </Container>
            </Wrapper>
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    const params = getParams(state, 'Project');
    const { id } = params;

    const projects = state.firestore.data.projects || {};
    const blocks = state.firestore.ordered[`${id}-blocks`] || [];
    return {
        params,
        project: projects[id] || {},
        projectId: id,
        blocks,
    }
};

export default firestoreConnect((state) => {
    const { id } = state.match.params;
    return [{
        collection: 'projects',
        // where: [['id', '==', id]],
        doc: id,
        // orderBy: [
        //     [ 'order', 'asc' ],
        // ],
	// where: [
	// 	[ "chainId", "==", props.chainId ],
	// ],
}, {
    collection: 'projects',
    // where: [['id', '==', id]],
    doc: id,
    subcollections: [{
        collection: 'blocks',
        orderBy: [
            [ 'order', 'asc' ],
        ],
    }],
    storeAs: `${id}-blocks`
    // orderBy: [
    //     [ 'order', 'asc' ],
    // ],
// where: [
// 	[ "chainId", "==", props.chainId ],
// ],
}]})(asPage(connect(mapStateToProps, null)(Project), 'Project', true, true));

