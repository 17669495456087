// @flow
import { getAction, ActionTypes } from '../actions';

export const initialState = {
};

export default (state = initialState, action) => {
    const { type, key, id, data } = action;


    switch (type) {
        case getAction('CREATE_UPLOAD', ActionTypes.SET): {
            return {
                ...state,
                [key]: {
                    ordered: [
                        ...state?.[key]?.ordered || [],
                        id,
                    ],
                    items: {
                        ...state?.[key]?.items || {},
                        [id]: {
                            ...state?.[key]?.items?.[id] || {},
                            ...data,
                        },
                    },
                },
            };
        }
        case getAction('UPDATE_UPLOAD', ActionTypes.SET): {
            const newState = {
                ...state,
                [key]: {
                    ...state?.[key],
                    items: {
                        ...state?.[key]?.items,
                        [id]: {
                            ...state?.[key]?.items?.[id] || {},
                            ...data,
                        },
                    },
                },
            };

            return newState;
        }
        case getAction('REMOVE_UPLOAD', ActionTypes.SET):
            const newItems = {
                ...state?.[key]?.items,
            };
            const newOrdered = [
                ...state?.[key]?.ordered,
            ];

            const index = newOrdered.indexOf(id);
            if (index > -1) {
                delete newItems[id];
                newOrdered.splice(index, 1);
            }

            return {
                ...state,
                [key]: {
                    ordered: newOrdered,
                    items: newItems,
                },
            };
    }

    return state;
};
