const config = {
    apiKey: "AIzaSyCaAxxgFPT4wdULoA7BOBTX4wRMk9fS5Dc",
    authDomain: "tmrijavec.firebaseapp.com",
    databaseURL: "https://tmrijavec.firebaseio.com",
    projectId: "tmrijavec",
    storageBucket: "tmrijavec.appspot.com",
    messagingSenderId: "1015288324118",
    appId: "1:1015288324118:web:7b5400bb87c5bfcb05d537"
};

export default config;
