
import React, { Fragment, useState } from 'react';

import {
    Table, THead, THeadTH,
} from './Blocks.style';

import useWindowSize from '../../../../utils/useWindowSize';
import theme from '../../../../themes/default';

import Block from '../Block';

import { Wrapper, Container } from '../../../Projects/components/Common/Layout.style';

const Blocks = ({ blocks, projectId, emptyBlocks, blocksCount, addNewCback, onDeleteCback }) => {

    const windowSize = useWindowSize();
    const isMobile = windowSize.width < theme.screenSize.mediumTablet;

    return (
        <Wrapper>
            <Container>
                <Table>
                    <colgroup>
                        <col width={isMobile ? '50%' : '100%'}></col>
                        {!isMobile && (<col width="50%"></col>)}
                        <col width="85"></col>
                    </colgroup>
                    <THead>
                        <tr>
                            <THeadTH>Block</THeadTH>
                            {!isMobile && (<THeadTH>Status</THeadTH>)}
                            <THeadTH>&nbsp;</THeadTH>
                        </tr>
                    </THead>
                    {blocks.map((block, i) => (
                        <Block
                            key={block.id}
                            block={block}
                            blockIdx={i}
                            blocks={blocks}
                            projectId={projectId}
                            blocksCount={blocksCount}
                            addNewCback={addNewCback}
                            onDeleteCback={onDeleteCback}
                        />
                    ))}
                    {emptyBlocks.map(block => (
                        <Block
                            key={block.id}
                            block={block}
                            projectId={projectId}
                            blocksCount={blocksCount}
                            addNewCback={addNewCback}
                            onDeleteCback={onDeleteCback}
                        />
                    ))}
                </Table>
            </Container>
        </Wrapper>
    );
}

export default Blocks;
