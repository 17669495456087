import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';

import { connect } from 'react-redux';
import asPage from 'components/Page';

import { useSelector } from 'react-redux';
import {
    useFirebase, isLoaded, isEmpty, useFirestoreConnect,
    useFirestore, firestoreConnect, populate,
} from 'react-redux-firebase';
import 'firebase/firestore';

import {
    BrowserRouter,
    Switch,
    Route,
    Redirect
} from 'react-router-dom';

import ProjectsList from './components/Projects';

import { AddButton } from './components/Common/Button.style';
import { ActionsList } from './components/Common/Lists.style';
import { ContentRow } from './components/Common/Row.style';
import { Wrapper, Container } from './components/Common/Layout.style';


const Projects = ({ projects }) => {
    const [emptyProjects, setNewEmptyProjects] = useState([]);

    const firebase = useFirebase();
    const firebaseAppAuth = firebase.auth();

    // const firestore = useFirestore();
    // const projectsList = firestore.collection('projects').orderBy('order').get();

    const auth = useSelector(state => state.firebase.auth)
    // console.log('isLoaded', isLoaded(auth));
    // console.log('isEmpty', isEmpty(auth));

    const providers = {
        googleProvider: new firebase.auth.GoogleAuthProvider(),
    };

    if (!isLoaded(auth)) {
        return <div>loading...</div>;
    }

    if (isEmpty(auth)) {
        return (
            <Redirect push to={'/'} />
        )
    }

    const hasProjects = projects.length > 0 || emptyProjects.length > 0;

    const projectsOrdered = projects.sort(function (a, b) { return (a.order > b.order) ? 1 : (a.order < b.order) ? -1 : 0; });

    return (
        <div>
            {hasProjects && (
                <ProjectsList
                    projects={projectsOrdered}
                    emptyProjects={emptyProjects}
                    projectsCount={projects.length}
                    onDeleteCback={(project) => {
                        const tmpEmptyProjects = emptyProjects.filter(p => p.id != project.id);
                        setNewEmptyProjects(tmpEmptyProjects);
                    }}
                    addNewCback={(project) => {
                        const tmpEmptyProjects = emptyProjects.filter(p => p.id != project.id);
                        setNewEmptyProjects(tmpEmptyProjects);
                    }}
                />
            )}

            <Wrapper noMargin>
                <Container noShadow>
                    <ActionsList>
                        <AddButton
                            type="button"
                            onClick={() => setNewEmptyProjects([
                                ...emptyProjects,
                                {
                                    id: '_' + Math.random().toString(36).substr(2, 9),
                                    isNew: true,
                                    order: projects.length + emptyProjects.length,
                                },
                            ])}
                        >
                            Add New Project
                        </AddButton>
                    </ActionsList>
                </Container>
            </Wrapper>
        </div>
    );
};

const mapStateToProps = state => ({
    // problems: getProblems(state),
    projects: state.firestore.ordered.projects || [],
});

// export default asPage(connect(mapStateToProps, null)(Projects), 'Projects', true);
export default firestoreConnect(() => [{
	collection: 'projects',
	orderBy: [
		[ 'order', 'asc' ],
	],
	// where: [
	// 	[ "chainId", "==", props.chainId ],
	// ],
}])(asPage(connect(mapStateToProps, null)(Projects), 'Projects', true, true));

