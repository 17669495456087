
import React, { Fragment, useState, useCallback, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';

import { useSpring } from 'react-spring'

import { EditorState, convertToRaw, convertFromRaw, convertFromHTML, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
// import htmlToDraft from 'html-to-draftjs';

import { useFirestore } from 'react-redux-firebase';

import Link from 'components/Link';
import { useToasts } from 'react-toast-notifications'

// import Dropzone from 'react-dropzone';
import { useDropzone } from 'react-dropzone';

import {
    Wrapper, SwitchLabel,
    ThumbsContainer, Thumb, ThumbInner, Img,
} from './Section.style';

import { SaveButton } from '../../../Projects/components/Common/Button.style';
import Switch from '../../../Projects/components/Switch';

import { InputLabel, InputField } from '../../../Projects/components/Common/Input.style';
import { ContentRow } from '../../../Projects/components/Common/Row.style';

import { UPLOAD_STATE, uploadFile } from '../../../../utils/uploader';
import { getImagePreview, getFileConfigWithSizes } from '../../../../utils/images';

import getUploader from '../../../../utils/s3uploader';

import ReactDynamicImport from 'react-dynamic-import';
const loader = () => import('../../../Projects/components/Wysiwyg');
const Editor = ReactDynamicImport({ loader });

const Section = ({ uploader, block, data, onDataChange, isTwoColumns }) => {
    // const onFilesDrop = (files) => {
    //     console.log('onFilesDrop', files);
    //     // return firebase.uploadFiles(filesPath, files, filesPath);
    // };

    // const onFileDelete = (file, key) => {
    //     console.log('onFileDelete', file, key);
    //     // return firebase.deleteFile(file.fullPath, `${filesPath}/${key}`);
    // };


    const dispatch = useDispatch();
    const [isImage, setIsImage] = useState(data.isImage || false);
    const [isHero, setIsHero] = useState(data.isHero || false);
    // const [image, setImage] = useState(data.image || null);
    const [title, setTitle] = useState(data.title || '');

    let contentState = EditorState.createEmpty();
    if (data.description) {
        const rawContent = convertFromRaw(JSON.parse(data.description.raw));
        contentState = EditorState.createWithContent(rawContent);
    }
    const [editorState, setEditorState] = useState(contentState);

    const initialFiles = [];
    // if (image) {
    //     initialFiles.push({
    //         name: image.public_id,
    //         preview: getImagePreview(image),
    //         // preview: `https://res.cloudinary.com/codersi/image/upload/f_auto/v${image.version}/${image.public_id}.jpg`,
    //         // https://res.cloudinary.com/codersi/image/upload/v1579303328/el3tuurrrwwbi3odsqtr.png
    //     });
    // }
    const [files, setFiles] = useState(initialFiles);
    // const onDrop = useCallback((acceptedFiles) => {
    //     acceptedFiles.forEach((file) => {
    //         const reader = new FileReader();

    //         reader.onabort = () => console.log('file reading was aborted');
    //         reader.onerror = () => console.log('file reading has failed');
    //         reader.onload = () => {
    //             // Do whatever you want with the file contents
    //             const binaryStr = reader.result;
    //             console.log(binaryStr);
    //         }
    //         reader.readAsArrayBuffer(file);
    //     });
    // }, []);

    // const [imageOpacityState, setImageOpacityState] = useState(image ? 1 : 0);
    // const { imageOpacity } = useSpring({
    //     // config: { mass: 1, tension: 170, friction: 70 },
    //     from: { imageOpacity: 0 },
    //     imageOpacity: imageOpacityState,
    // });


    const availableSizes = [{
        name: 'full',
        size: 2400,
        quality: 0.87,
    }, {
        name: 'mobile',
        size: 1200,
        quality: 0.95,
    }, {
        name: 'cover',
        size: 640,
        quality: 0.87,
    }, {
        name: 'textBg',
        size: 10,
        quality: 1.0,
        filters: [{
            type: 'blur',
            amount: '0.5px',
            fill: 'white',
        }],
    }, {
        name: 'blur',
        size: 300,
        quality: 1.0,
        filters: [{
            type: 'blur',
            amount: '7px',
            fill: 'white',
        }],
    }];

    const getUploaderProps = block.isNew ? () => ({}) : getUploader({
        collection: `${block.id.substr(0, 6)}`,
        sizes: availableSizes,
        accept: 'image/*, video/*',
    });
    const { key, getRootProps, getInputProps } = getUploaderProps();

    const tmpImageIdx = uploader?.[key]?.ordered?.[0];
    const tmpImage = uploader?.[key]?.items?.[tmpImageIdx];
    const image = tmpImage || data?.image;

    // const { getRootProps, getInputProps } = useDropzone({
    //     accept: 'image/*, video/*',
    //     onDrop: acceptedFiles => {
    //         const filteredFiles = [acceptedFiles[0]];
    //         setFiles(filteredFiles.map(file => Object.assign(file, {
    //             preview: URL.createObjectURL(file)
    //         })));

    //         filteredFiles.map(file => {
    //             uploadFile({
    //                 file,
    //                 stateChangeCback: (type, file, index, data) => {
    //                     switch (type) {
    //                         case UPLOAD_STATE.Progress:
    //                             setImageOpacityState(data.percent / 150.0);
    //                             break;
    //                         case UPLOAD_STATE.Done:
    //                             setImageOpacityState(1);
    //                             break;
    //                     }
    //                 },
    //             })
    //             .then(data => {
    //                 handleChange(data, 'image');
    //             });
    //         });
    //     },
    // });

    // const thumbs = files.map(file => (
    //     <Thumb key={file.name} style={{
    //         opacity: imageOpacity,
    //     }}>
    //         <ThumbInner>
    //             <Img src={file.preview} />
    //         </ThumbInner>
    //     </Thumb>
    // ));

    const { addToast } = useToasts();

    const thumb = image && (
        <Thumb key={image.name || image.path} style={{
            opacity: image.percent ? (image.percent / 100.0) : 1,
        }}>
            <ThumbInner>
                <Img src={getImagePreview(image?.cover || image)} />
            </ThumbInner>
        </Thumb>
    );

    // useEffect(() => () => {
    //     // Make sure to revoke the data uris to avoid memory leaks
    //     files.forEach(file => URL.revokeObjectURL(file.preview));
    // }, [files]);

    // useEffect(() => () => {
    //     const descriptionRaw = convertToRaw(editorState.getCurrentContent());
    //     const newData = {
    //         title,
    //         isImage,
    //         isHero,
    //         description: {
    //             raw: JSON.stringify(descriptionRaw),
    //             html: draftToHtml(descriptionRaw),
    //         },
    //     };

    //     const tmpImageIdx = uploader?.[key]?.ordered?.[0];
    //     const tmpImage = uploader?.[key]?.items?.[tmpImageIdx];
    //     const newImage = tmpImage || data?.image;

    //     console.log('image', newImage)

    //     newData.image = getFileConfigWithSizes(image, availableSizes);
    //     onDataChange(newData);
    // }, [uploader, key, image]);

    const handleChange = (event, type) => {
        const descriptionRaw = convertToRaw(editorState.getCurrentContent());
        const newData = {
            title,
            isImage,
            isHero,
            description: {
                raw: JSON.stringify(descriptionRaw),
                html: draftToHtml(descriptionRaw),
            },
        };

        switch(type) {
            case 'isImage':
                setIsImage(event);
                newData.isImage = event;
                break;
            case 'isHero':
                setIsHero(event);
                newData.isHero = event;
                break;
            case 'image':
                addToast('Image Saved', {
                    appearance: 'success',
                    autoDismiss: true,
                });
                newData.image = getFileConfigWithSizes(image, availableSizes);
                break;
            case 'title':
                setTitle(event.target.value);
                newData.title = event.target.value;
                break;
            case 'description':
                setEditorState(event);
                const newDescriptionRaw = convertToRaw(event.getCurrentContent());
                newData.description = {
                    raw: JSON.stringify(newDescriptionRaw),
                    html: draftToHtml(newDescriptionRaw),
                };
                break;
            default:
                break;
        }

        onDataChange(newData);
    };

    const handleDelete = (document) => {
    };

    const handleSubmit = (event) => {
    }

    const imageSaveDisabled = (image?.percent || 0) < 100;
    const isNewlyUploaded = image?.percent != null;

    return (
        <Wrapper>
            <ContentRow right center noMargin>
                <Switch enabled={isImage} onToggle={(data) => handleChange(data, 'isImage')} />
                <SwitchLabel>Is Image?</SwitchLabel>
            </ContentRow>
            {isImage && (
                <Fragment>
                    {!isTwoColumns && (
                        <ContentRow right center noMargin>
                            <Switch enabled={isHero} onToggle={(data) => handleChange(data, 'isHero')} />
                            <SwitchLabel>Is Hero Image?</SwitchLabel>
                        </ContentRow>
                    )}
                    {!block.isNew && (
                        <ContentRow noMargin={isHero}>
                            {/* <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>
                                {({getRootProps, getInputProps}) => (
                                    <section>
                                        <div {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <p>Drag 'n' drop some files here, or click to select files</p>
                                        </div>
                                    </section>
                                )}
                            </Dropzone> */}
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p>Drag 'n' drop the image here, or click to select one</p>
                            </div>
                            <ThumbsContainer>
                                {thumb}
                            </ThumbsContainer>
                            {(isNewlyUploaded && imageSaveDisabled) && <span>Uploading...</span>}
                            {(isNewlyUploaded && !imageSaveDisabled) && (
                                <SaveButton
                                    type="button"
                                    onClick={() => handleChange(null, 'image')}
                                >Save Image</SaveButton>
                            )}
                        </ContentRow>
                    )}
                </Fragment>
            )}
            {!isImage && (
                <Fragment>
                    <ContentRow noMargin>
                        <InputLabel>Title</InputLabel>
                        <InputField
                            type="text"
                            name="name"
                            autocomplete="off"
                            spellcheck="false"
                            value={title}
                            onChange={(event) => handleChange(event, 'title')}
                        />
                    </ContentRow>
                    <ContentRow noMargin>
                        <InputLabel>Description</InputLabel>
                        <Editor
                            editorState={editorState}
                            onStateChange={(state) => handleChange(state, 'description')}
                        />
                    </ContentRow>
                </Fragment>
            )}
        </Wrapper>
    );
}

const mapStateToProps = (state) => {
    return {
        uploader: state.uploader,
    };
};

export default connect(mapStateToProps, null)(Section);
