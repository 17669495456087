/* eslint prefer-template: 0 */
import React, {
    useMemo,
    useRef
} from 'react';

import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import { getAction, ActionTypes } from '../actions';
import {  ImageTools } from '../utils/images';

import axios from 'axios';

const UPLOAD_STATE = {
    Progress: 'progress',
    Size: 'size',
    Done: 'done',
};

const getImageVideoSize = (type, src) => new Promise((resolve, reject) => {
    if (type === 'image') {
        const img = document.createElement('img');
        img.onload = () => {
            resolve({
                width: img.width,
                height: img.height,
            });
        };
        img.src = src;
    }
    else {
        const video = document.createElement('video');
        video.onload = () => {
            resolve({
                width: video.videoWidth,
                height: video.videoHeight,
            });
        };
        video.src = src;
    }
});

const uploadFile = ({
    file,
    type,
    folder,
    width,
    height,
    index = 0,
    stateChangeCback = (type, file, index, data) => {},
}) => new Promise((resolve, reject) => {
    const { preview } = file;

    const signFormData = {
        fileName: file.name,
        fileType: type || file.type,
        folder,
    };

    axios.post('/api/s3/sign/', signFormData)
    .then(response => {
        const returnData = response.data.data.returnData;
        const { signedRequest, url, key } = returnData;

        const headers = {
            'content-type': 'image/*',
        };

        const fileType = signFormData.fileType.startsWith('video/') ? 'video' : 'image';

        if (fileType === 'video') {
            headers['content-type'] = 'video/*';
        }

        // Put the fileType in the headers for the upload
        const options = {
            headers,
            onUploadProgress: (progressEvent) => {
                const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                stateChangeCback(UPLOAD_STATE.Progress, file, index, {percent});
            },
        };

        if (width && height) {
            axios.put(signedRequest, file, options)
            .then(result => {
                const data = {
                    tmpId: file.tmpId || '',
                    url,
                    fileType,
                    public_id: key,
                    original_filename: file.name,
                    type: 's3',
                    width,
                    height,
                };

                stateChangeCback(UPLOAD_STATE.Done, file, index, data );
                resolve(data);
            })
            .catch(error => {
                console.log('ERROR', error);
            });
        }
        else {
            getImageVideoSize(fileType, preview)
            .then(size => {
                stateChangeCback(UPLOAD_STATE.Size, file, index, size );

                axios.put(signedRequest, file, options)
                .then(result => {
                    const data = {
                        tmpId: file.tmpId || '',
                        url,
                        fileType,
                        public_id: key,
                        original_filename: file.name,
                        type: 's3',
                        ...size,
                    };

                    stateChangeCback(UPLOAD_STATE.Done, file, index, data );
                    resolve(data);
                })
                .catch(error => {
                    console.log('ERROR', error);
                });
            })
            .catch((error) => {
                console.log('ERROR getImageVideoSize', error);
            });
        }
    })
    .catch(error => {
        console.log('Axios error', JSON.stringify(error));
    });
});

const getUploader = ({
    collection,
    accept = 'image/*',
    limit = 0,
    sizes = [],
}) => {
    const uploaderRef = useRef(Math.random().toString(36).substr(2, 5));

    const getUploaderProps = useMemo(
        () => () => {
            const dispatch = useDispatch();
            const tmpStoreKey = uploaderRef.current;

            const { getRootProps, getInputProps } = useDropzone({
                accept,
                onDrop: acceptedFiles => {
                    const droppedFiles = acceptedFiles.map(file => Object.assign(file, {
                        percent: 0,
                        tmpId: Math.random().toString(36).substr(2, 5),
                        preview: URL.createObjectURL(file),
                        width: 0,
                        height: 0,
                    }));

                    const hardLimit = limit ? Math.min(limit, droppedFiles.length) : droppedFiles.length;
                    for(let a = 0; a < hardLimit; a += 1) {
                        const item = droppedFiles[a];
                        const fileType = item.type.startsWith('video/') ? 'video' : 'image';

                        if (fileType === 'video') {
                            ImageTools.videoEdit({
                                video: item,
                            }, (blob, width, height) => {
                                const preview = Object.assign(new File([blob], `${item.name}.jpg`, {
                                    type: 'image/jpeg',
                                }), {
                                    tmpId: item.tmpId,
                                });

                                dispatch({ type: getAction('CREATE_UPLOAD', ActionTypes.SET), key: tmpStoreKey, id: item.tmpId, data: {
                                    ...item,
                                    width,
                                    height,
                                    preview: URL.createObjectURL(preview),
                                }});

                                const items = [{
                                    name: 'full',
                                    type: item.type,
                                    file: item,
                                }, {
                                    name: 'cover',
                                    type: preview.type,
                                    file: preview,
                                }];
                                for (let b = 0; b < items.length; b += 1) {
                                    const { name } = items[b];

                                    uploadFile({
                                        file: items[b].file,
                                        folder: `${collection}/video`,
                                        width,
                                        height,
                                        type: items[b].type,
                                        stateChangeCback: (type, file, index, data) => {
                                            switch (type) {
                                                case UPLOAD_STATE.Progress:
                                                    if (name === 'full') {
                                                        const updatedData = {
                                                            ...data,
                                                            tmpId: item.tmpId,
                                                            width: parseInt(Math.floor(width + 0.5)),
                                                            height: parseInt(Math.floor(height + 0.5)),
                                                        };
                                                        dispatch({ type: getAction('UPDATE_UPLOAD', ActionTypes.SET), key: tmpStoreKey, id: file.tmpId, data: updatedData });
                                                    }
                                                    break;
                                                case UPLOAD_STATE.Done:
                                                    const updatedData = {};
                                                    if (name === 'full') {
                                                        updatedData.url = data.url;
                                                        updatedData.fileType = data.fileType;
                                                        updatedData.publicId = data.public_id;
                                                        updatedData.width = parseInt(Math.floor(width + 0.5));
                                                        updatedData.height = parseInt(Math.floor(height + 0.5));
                                                    }
                                                    else {
                                                        updatedData[name] = {
                                                            url: data.url,
                                                            fileType: data.fileType,
                                                            publicId: data.public_id,
                                                            width: parseInt(Math.floor(width + 0.5)),
                                                            height: parseInt(Math.floor(height + 0.5)),
                                                        };
                                                    }
                                                    dispatch({ type: getAction('UPDATE_UPLOAD', ActionTypes.SET), key: tmpStoreKey, id: file.tmpId, data: updatedData });
                                                break;
                                            }
                                        },
                                    });
                                }
                            });
                        }
                        else if (fileType === 'image') {
                            dispatch({ type: getAction('CREATE_UPLOAD', ActionTypes.SET), key: tmpStoreKey, id: item.tmpId, data: item });

                            for (let b = 0; b < sizes.length; b += 1) {
                                const { size, name, quality = 1, filters = [] } = sizes[b];
                                ImageTools.edit({
                                    image: item,
                                    filters,
                                    type: item.type,
                                    quality,
                                    size,
                                }, (blob, width, height) => {
                                    const newFile = Object.assign(new File([blob], `${item.name}`, {
                                        type: item.type,
                                    }), {
                                        tmpId: item.tmpId,
                                    });

                                    uploadFile({
                                        file: newFile,
                                        folder: `${collection}/s${size}`,
                                        width,
                                        height,
                                        type: item.type,
                                        stateChangeCback: (type, file, index, data) => {
                                            switch (type) {
                                                case UPLOAD_STATE.Progress:
                                                    if (name === 'full') {
                                                        const updatedData = {
                                                            ...data,
                                                            tmpId: item.tmpId,
                                                            width: parseInt(Math.floor(width + 0.5)),
                                                            height: parseInt(Math.floor(height + 0.5)),
                                                        };
                                                        dispatch({ type: getAction('UPDATE_UPLOAD', ActionTypes.SET), key: tmpStoreKey, id: file.tmpId, data: updatedData });
                                                    }
                                                    break;
                                                case UPLOAD_STATE.Done:
                                                    const updatedData = {};
                                                    if (name === 'full') {
                                                        updatedData.url = data.url;
                                                        updatedData.fileType = data.fileType;
                                                        updatedData.publicId = data.public_id;
                                                        updatedData.width = parseInt(Math.floor(width + 0.5));
                                                        updatedData.height = parseInt(Math.floor(height + 0.5));
                                                    }
                                                    else {
                                                        updatedData[name] = {
                                                            url: data.url,
                                                            fileType: data.fileType,
                                                            publicId: data.public_id,
                                                            width: parseInt(Math.floor(width + 0.5)),
                                                            height: parseInt(Math.floor(height + 0.5)),
                                                        };
                                                    }
                                                    updatedData.preview = URL.createObjectURL(blob);
                                                    dispatch({ type: getAction('UPDATE_UPLOAD', ActionTypes.SET), key: tmpStoreKey, id: file.tmpId, data: updatedData });
                                                break;
                                            }
                                        },
                                    });
                                });
                            }
                        }
                    }
                },
            });

            return {
                key: tmpStoreKey,
                getRootProps,
                getInputProps,
            };
        },
        [uploaderRef, collection, accept, limit, sizes]
    );

    return getUploaderProps;
};

export default getUploader;
