import React, { Component, Fragment, PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import GA from 'react-ga';

import { useTransition, animated } from 'react-spring';

import ThemeProvider from '../../components/ThemeProvider';
import theme from '../../themes/default';
import GlobalStyle from '../../themes/global';

import posed, { PoseGroup } from 'react-pose';

import { renderChildRoutes } from '../../routes';

import { Main, Page } from './App.style';

// const helmetConstants = require('react-helmet/lib/HelmetConstants.js');
// helmetConstants.HELMET_ATTRIBUTE = 'data-trh';

// const RouteContainer = posed.div({
//     enter: { opacity: 1, y: 0, beforeChildren: true },
//     exit: { opacity: 0, y: 0 },
// });

// class App extends PureComponent {
//     componentDidMount() {
//         this.props.history.listen((location, action) => {
//             if (action === 'PUSH') {
//                 setTimeout(() => {
//                     window.scrollTo(0, 0);
//                 }, 0);
//             }
//         });

//         GA.pageview(window.location.pathname + window.location.search);
//     }

//     render() {
//         const { location, route } = this.props;
//         const { key } = location || {};

//         return (
//             <Fragment>
//                 <Helmet titleTemplate="Tjasa Rijavec – TMRijavec.com">
//                     <title>Tjasa Rijavec</title>
//                     <meta property="og:url"                content="https://tmrijavec.com/" />
//                     <meta property="og:type"               content="website" />

//                     <meta name="generator" content="Coder Engine" />
//                     <meta name="msapplication-TileColor" content="#ffffff" />
//                     <meta name="theme-color" content="#ffffff" />
//                 </Helmet>

//                 <ThemeProvider theme={theme}>
//                     <Fragment>
//                         <GlobalStyle whiteColor />
//                         <Main>
//                             <PoseGroup>
//                                 <RouteContainer key={key || 'root'}>
//                                     {renderChildRoutes({ route })}
//                                 </RouteContainer>
//                             </PoseGroup>
//                         </Main>
//                     </Fragment>
//                 </ThemeProvider>
//             </Fragment>
//         );
//     }
// }



const App = ({ location, route }) => {
    const transitions = useTransition(location, location => location.pathname, {
        from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
        enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
        leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
    });

    const { key } = location || {};

    return (
        <Fragment>
            <Helmet titleTemplate="Tjasa Rijavec – TMRijavec.com">
                <title>Tjasa Rijavec</title>
                <meta property="og:url"                content="https://tmrijavec.com/" />
                <meta property="og:type"               content="website" />

                <meta name="generator" content="Coder Engine" />
                <meta name="msapplication-TileColor" content="#ffffff" />
                <meta name="theme-color" content="#ffffff" />
            </Helmet>

            <ThemeProvider theme={theme}>
                <Fragment>
                    <GlobalStyle whiteColor />
                    <Main>
                        {renderChildRoutes({ route })}
                    </Main>
                </Fragment>
            </ThemeProvider>
        </Fragment>
    );
    // return transitions.map(({ item: loc, props, key: itemKey }) => {
    //     console.log('route', route, loc);
    //     return (
    //         <animated.div key={itemKey} style={props}>
    //             <Helmet titleTemplate="Tjasa Rijavec – TMRijavec.com">
    //                 <title>Tjasa Rijavec</title>
    //                 <meta property="og:url"                content="https://tmrijavec.com/" />
    //                 <meta property="og:type"               content="website" />

    //                 <meta name="generator" content="Coder Engine" />
    //                 <meta name="msapplication-TileColor" content="#ffffff" />
    //                 <meta name="theme-color" content="#ffffff" />
    //             </Helmet>

    //             <ThemeProvider theme={theme}>
    //                 <Fragment>
    //                     <GlobalStyle whiteColor />
    //                     <Main>
    //                         {renderChildRoutes({ route })}
    //                     </Main>
    //                 </Fragment>
    //             </ThemeProvider>
    //         </animated.div>
    //     );
    // });
};

export default App;
